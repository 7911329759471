import {  AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HubImage, ImageService } from '../shared/services/image.service';
import { ImageEditorComponent } from './components/image-editor/image-editor.component';
import { Subscription, lastValueFrom } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../shared/services/modal.service';
import { environment } from 'src/environments/environment';
import { UserActionService } from '../shared/services/user-action.service';
import { ClientType, MessageType } from '../utils/const';
import { MeesmaService } from '../shared/services/meesma.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../shared/services/app.service';

@Component({
  selector: 'app-proposer',
  templateUrl: './proposer.component.html',
  styleUrls: ['./proposer.component.css']
})
export class ProposerComponent implements AfterViewInit, OnDestroy, OnInit {
  imageData!: string
  uuid!: string
  croppedImageData!: string
  currentFilter = 0
  isPrint = false
  isRecommend = false
  isFace = false
  isLoading = false
  loadingText = ''
  reloadFlag = false
  returnPath = 'doctor/linked_devices/proposer_return'
  client!: string
  translations: {[key: string]: string} = {}


  private _originalImage!: string

  @ViewChild('imageEditor') imageEditor!: ImageEditorComponent
  private _onLangChangeSub!: Subscription;

  constructor(private spinner: NgxSpinnerService, private _modalService: ModalService,
    private _router: Router, private _imageService: ImageService,
    private _route: ActivatedRoute, private _userActionService: UserActionService,
    private _meesmaService: MeesmaService, private _appService: AppService,
    private translate: TranslateService,
  ) {
    this.translate.get(["proposer.imageSavedSuccessfully",
      "proposer.saveEditedImageConfirmation",
      "shared.no",
      "shared.yes",
      "shared.confirm",
      "proposer.serverError",
      "proposer.sessionExpired",
      "proposer.imageNotFound",
      "proposer.unknownError"
    ]).subscribe((translations) => {
      this.translations = translations
    })
  }
  ngOnInit(): void {
    this.client = this._appService.getClientType();
    this._userActionService.announceClientType(this.client);
    this._onLangChangeSub = this.translate.onLangChange.subscribe(() => {
      this.translate.get(["proposer.imageSavedSuccessfully",
      "proposer.saveEditedImageConfirmation",
      "shared.no",
      "shared.yes",
      "shared.confirm",
      "proposer.serverError",
      "proposer.sessionExpired",
      "proposer.imageNotFound",
      "proposer.unknownError"
      ]).subscribe((translations) => {
        this.translations = translations
      })
    })
  }
  ngOnDestroy(): void {
    if(this._onLangChangeSub)
      this._onLangChangeSub.unsubscribe()
  }
  ngAfterViewInit(): void {
    // this._originalImage = this.imageData = "/assets/img/pola2.jpeg"
    this._getImage();
  }

  // event method
  async onSaveBack() {
    if(this.client == ClientType.MEESMA) {
      this.saveToMeesma();
    } else {
      this.saveToHub();
    }
  }

  async saveToMeesma() {
    this.spinner.show('save-spinner')
    const image: any = this._shouldSave ? await this.toBuffer(this.imageEditor.getCroppedImage()) : this.imageData;
    this._meesmaService.emit(MessageType.EDITED, { room: this._meesmaService.room, image: image });
    this._meesmaService.on("disconnect", () => {
      this.spinner.hide('save-spinner')
      this._modalService.openNotification(this.translations["proposer.imageSavedSuccessfully"]).then(() => {
        sessionStorage.removeItem("room");
        sessionStorage.removeItem("client");
        this._meesmaService.off("disconnect");
        this._meesmaService.disconnect();
        window.location.reload();
      })
    })
  }

  async toBuffer(base64: string) {
    return (await (await fetch(base64)).blob()).arrayBuffer();
  }

  async saveToHub() {
    const token = sessionStorage.getItem('hub-token');
    if (!token) {
      this._router.navigate(['unauthorized']);
      return;
    }
    if (this._shouldSave) {
      return window.location.replace(`${environment.domain}/${this.returnPath}?uuid=${this.uuid}`);
    }
    else {
      const res = await this._modalService.openConfirm(
        this.translations["shared.confirm"],
        this.translations["proposer.saveEditedImageConfirmation"],
        this.translations["shared.yes"],
        this.translations["shared.no"])
      if (res) {
        this.spinner.show('save-spinner')
        this.croppedImageData = this.imageEditor.getCroppedImage();
        const blob = await (await fetch(this.croppedImageData)).blob();
        const file: File = new File([blob], 'editedImage', { type: blob.type });
        this._imageService.uploadImage(token, file, this._image)
          .then((res) => {
            window.location.replace(`${environment.domain}/${this.returnPath}?uuid=${res.uuid}`);
          }).catch(() => {
              this._modalService.openNotification(this.translations["proposer.serverError"])
          }).finally(() => {
            this.spinner.hide('save-spinner')
          });
      } else {
        window.location.replace(`${environment.domain}/${this.returnPath}?uuid=${this.uuid}`);
      }
    }
  }

  onReloadImage() {
    this._getImage();
  }

  reloadComponent(data: boolean) {
    this.reloadFlag = data;
  }

  onPrintClosed(): void {
    this.isPrint = false;
  }

  onFaceSimulation() {
    this._userActionService.announceFaceImage(this._originalImage);
    this.isFace = true;
    this._isDrawing = false;
    this.isRecommend = false;
  }
  // Private
  private _image!: HubImage;
  public get image(): HubImage {
    return this._image;
  }
  public set image(value: HubImage) {
    this._image = value;
  }
  public get isHub() {
    return this.client === ClientType.HUB;
  }
  private _isDrawing = true;
  public get isDrawing(): boolean {
    return this._isDrawing;
  }
  public set isDrawing(value: boolean) {
    this._isDrawing = value;
    if (!value) {
      this.spinner.show('move-spinner')
      setTimeout(() => {
        this.croppedImageData = this._shouldSave ? this.imageEditor.getCroppedImage() : this.imageData;
        this.spinner.hide('move-spinner')
      }, 100);
    }
  }

  private get _shouldSave() {
    return this.imageEditor?.hasChanged() || this._originalImage != this.imageData
  }

  private _getImage() {
    this.spinner.show('filter-spinner')
    if(this.client == ClientType.MEESMA) {
      this._getMeesmaImage();
    } else {
      this._getHubImage();
    }
  }

  private _getMeesmaImage() {
    this._meesmaService.emit(MessageType.READY, { room: this._meesmaService.room });
    this._meesmaService.on("message", (msg) => {
      if(msg.type != MessageType.ORIGINAL) return;
      this._originalImage = this.imageData = msg.data.image;
      this.imageEditor.isError = false;
      this._meesmaService.off("message");
    })
  }

  private _getHubImage() {
    lastValueFrom(this._imageService.getImage(sessionStorage.getItem('hub-token') || '', this._route.snapshot.queryParams['uuid']))
      .then(res => {
        this.image = res
        this.uuid = this._image.uuid
        this._originalImage = this.imageData = this._image.file_url
        this.imageEditor.isError = false
      })
      .catch(err => {
        this.spinner.hide('filter-spinner')
        switch (err.status) {
          case 403: {
            this._modalService.openNotification(this.translations["proposer.sessionExpired"])
            break;
          }
          case 404: {
            this._modalService.openNotification(this.translations["proposer.imageNotFound"])
            break;
          }
          default: {
            this._modalService.openNotification(this.translations["proposer.unknownError"])
            this.imageEditor.isError = true
            break;
          }
        }
      })
  }
}
